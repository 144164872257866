<template>
    <div class="container-active-participant">
        <form class="active-participant" @submit.prevent="submitForm" ref="formVolunteer">
            <div class="active-participant__title">
                Я — волонтер
            </div>
            <div class="active-participant__inputs">
                <div class="active-participant__left">
                    <span v-if="errors.date" class="error-label">{{errors.date}}</span>
                    <span class="select-label">Дата заходу</span>
                    <v-date-picker
                        v-model="dateEvent"
                        :update-on-input="true"
                        :model-config="masks"
                        locale="uk"
                        :dark="true"
                        :popover="{ visibility: 'click' }"
                    >
                        <template v-slot="{ inputEvents, inputValue, togglePopover}">
                            <input
                                class="date-input"
                                :model-config="masks"
                                :isHovered="true"
                                readonly
                                v-on="inputEvents"
                                v-model="dateEvent"
                            />
                        </template>
                    </v-date-picker>
                    <div class="active-participant__search">
                        <span v-if="errors.event_id" class="error-label">{{errors.event_id}}</span>
                        <CustomSelectV2
                            :options="eventList"
                            v-model="eventId"
                            :searchable="true"
                            label="Назва заходу"
                            required="required"
                        />
                    </div>
                    <div class="active-participant__file">
                        <span v-if="errors.file" class="error-label">{{errors.file}}</span>
                        <span class="input-label">Додати файл</span>
                        <input hidden type="file" id="add-file" ref="addFile" @change="fileUpload"/>
                        <label class="label-for-file" for="add-file"><span v-if="file">{{file.name}}</span></label>
                    </div>
                </div>
                <div class="active-participant__right">
                    <div class="active-participant__description">
                        <span class="input-label">Опис</span>
                        <textarea name="description" class="input-project" type="text" v-model="description"></textarea>
                    </div>
                </div>
            </div>
            <div class="active-participant__btn">
                <button class="btn-cancel" type="button" :class="{disableBtn : toggleButton}" @click="clearForm">
                    Скасувати
                </button>
                <button class="btn-save" type="submit">
                    Зберегти
                </button>
            </div>
            <div class="form-popup" v-if="popupStatus" @click="closePopup"></div>
            <div class="popup-container" v-if="popupStatus">
                <div class="close-popup" @click="closePopup">&#10006;</div>
                <div class="popup-title">
                    Дякуємо!
                </div>
                <div class="popup-description">
                    Форма успішно збережена. <br> Будь ласка, закрийте це вікно, щоб перейти до списку.
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import DatepickerComponent from "../components/ui/DatepickerComponent";
import CustomSelectV2 from "@/components/ui/CustomSelectV2";
import PromotionService from "@/services/PromotionService";

export default {
    name: "FormVolunteer",
    components: {
        DatepickerComponent,
        CustomSelectV2
    },
    data () {
        return{
            dateEvent: null,
            file: null,
            description: '',
            eventList: [],
            eventId: '',
            toggleButton: true,
            errors: {
                event_id: '',
                date: '',
                file: '',
                description: ''
            },
            update: false,
            userId: null,
            day: null,
            month: null,
            year: null,
            masks: {
                type: 'string',
                mask: 'YYYY-MM-DD'
            },
            searchable: true,
            popupStatus: false,
            allEvents: []
        }
    },
    watch:{
        userId() {
            this.getEvent()
        },
        dateEvent() {
            this.eventList = []

            let date = new Date(this.dateEvent)

            this.year = date.getFullYear()
            this.day = date.getDate()
            this.month = date.getMonth() + 1
            if (this.dateEvent){
                this.toggleButton = false
            }
            this.getEventList()
        },
        eventId(){
            this.toggleButton = !this.dateEvent;
            this.dateEvent = this.allEvents.find(event => event.id == this.eventId).date

        }
    },
    methods:{
        fileUpload(){
            this.file = this.$refs.addFile.files[0];
        },
        async getEventList(){
            let response;

            if (this.dateEvent){
                response = await PromotionService.getEventsDropdown(this.day, this.month, this.year)
            } else {
                response = await PromotionService.getEventsDropdown()
            }

            this.allEvents = response
            this.allEvents = response

            response.forEach(event => {
                this.eventList.push(
                    {"label": event.title, 'value': event.id}
                )
            })
        },
        clearForm(){
            if (!this.toggleButton){
                this.description = null
                this.eventId = null
                this.file = null
                this.dateEvent = null
                this.toggleButton = true
            }
        },
        async submitForm(){
            let formData = new FormData(this.$refs.formVolunteer);

            formData.append('event_id', this.eventId)

            if (this.file){
                formData.append('files[0]', this.file)
            }

            if (!this.update) {
                let resp = await PromotionService.uploadProjectForm(formData, 2).catch(this.handleError)

                if (resp) {
                    this.clearForm()
                    this.errors = {}
                    this.popupStatus = true
                }
            }

            if (this.update){
                let resp = await PromotionService.uploadEditProject(formData, +this.$route.params.id).catch(this.handleError)

                if (resp){
                    this.clearForm()
                    this.errors = {}
                    this.popupStatus = true
                }
            }
        },
        handleError(resp){
            if (resp.statusCode === 422) {
                this.errors = resp.data;
            }
        },
        async getUserId(){
            let resp = await PromotionService.getUserId().catch(this.handleError)
            this.userId = resp
        },
        async getEvent() {
            if (this.update){
                let resp = await PromotionService.getPromotionList(this.userId, 2).catch(this.handleError)
                let event = resp.find(item => {
                    return +item.id === +this.$route.params.id
                })

                this.description = event.description
                this.dateEvent = event.event.date
                this.eventId = event.event.id
                this.file = event.files[0]
            }
        },
        closePopup() {
            this.popupStatus = false
            this.$router.push('/encouragement/2')
        }
    },
    created() {
        this.getEventList()

        if (this.$route.name === 'volunteer-update'){
            this.update = true
        }

        this.getUserId()
    }
}
</script>

<style scoped lang="less">
.container-active-participant {
    background-color: #f5f5f5;;
    width: 97%;
    min-height: 100vh;
    float: right;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 20px;

    @media (max-width: 992px){
        width: 100%;
        margin: 0;
    }

    .active-participant {
        margin-top: 36px;
        padding: 0 40px;
        width: 100%;
        min-height: 525px;
        border-radius: 10px;
        border: solid 1px #cdd4e0;
        background-color: #fff;
        &__title {
            font-family: @secondFontFamily;
            margin-top: 50px;
            font-weight: 500;
            font-size: 24px;
            color: #1a2744;
            display: flex;
            justify-content: center;
        }
        &__inputs {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;

            @media (max-width: 768px){
                flex-direction: column;
            }

            .error-label{
                font-size: 12px;
                color: #ffc900;
                height: 0;
                display: block;
                position: absolute;
                right: 0;

                @media (max-width: 768px){
                    position: relative;
                    left: 140px;
                }
            }

            .active-participant__left{
                margin-right: 40px;

                @media (max-width: 768px){
                    margin-right: 0;
                }

            }
            .active-participant__left, .active-participant__right{
                width: 50%;
                position: relative;

                @media (max-width: 768px){
                    width: 100%;
                }

                .active-participant__search{
                    margin-top: 20px;
                }

                .select-label {
                    font-size: 12px;
                    color: #1a2744;
                    position: relative;
                    left: 20px;
                    bottom: 5px;
                }
                .active-participant__file, .active-participant__description{
                    margin-top: 20px;
                    .input-label, .select-label {
                        font-size: 12px;
                        color: #1a2744;
                        position: relative;
                        left: 20px;
                        bottom: 5px;
                    }
                    .label-for-file{
                        border-radius: 10px;
                        width: 100%;
                        height: 50px;
                        border: solid 1px #cdd4e0;
                        cursor: pointer;
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding-left: 20px;
                        &:after{
                            content: url('../assets/images/attach-paperclip-symbol.png');
                            cursor: pointer;
                            position: absolute;
                            right: 20px;
                            top: 15px;
                        }
                    }
                }
                .active-participant__description{
                    margin-top:0;

                    @media (max-width: 768px){
                        margin-top: 20px;
                    }

                    .input-label:before{
                        content: '';
                    }
                    .input-project{
                        display: block;
                        outline: none;
                        width: 100%;
                        height: 235px;
                        border: 1px solid #cdd4e0;
                        border-radius: 10px;
                        font-size: 16px;
                        font-weight: 500;
                        padding: 20px;
                        resize: none;
                        &:focus{
                            border-color: #ffc900;
                        }
                    }
                }
            }
        }
        &__btn{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;

            @media (max-width: 768px){
                margin-bottom: 40px;
            }

            .btn-cancel, .btn-save{
                font-family: @secondFontFamily;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.33;
                width: 220px;
                height: 50px;
                background-color: #1a2744;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                margin-right: 11px;
                cursor: pointer;
                box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);

                @media (max-width: 768px){
                    width: 50%;
                }
            }
            .disableBtn{
                opacity: 0.4;
                cursor: auto;
                box-shadow: none;
            }
        }
    }
    .date-input{
        width: 100%;
        position: relative;
        outline: none;
        height: 50px;
        border-radius: 10px;
        border: solid 1px #cdd4e0;
        cursor: pointer;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #1a2744;
        background: #fff url('../assets/images/calendar-ico.svg') no-repeat center right 20px;
    }
    ::v-deep .vc-popover-content{
        position: relative!important;
        left: 100%!important;
    }
    .form-popup{
        position: fixed;
        background-color: #1a2744;
        width: 200vw;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.6;
        z-index: 1;
    }
    .popup-container {
        z-index: 5;
        position: fixed;
        top: 50%;
        right: 25%;
        width: 610px;
        height: 196px;
        border-radius: 10px;
        box-shadow: 0 24px 24px -14px rgba(157, 163, 174, 0.1);
        border: solid 1px #cdd4e0;
        background-color: #f5f5f5;
        padding: 31px 74px 48px 80px;

        .close-popup {
            position: absolute;
            cursor: pointer;
            right: 14px;
            top: 14px;
        }

        .popup-title {
            font-family: @secondFontFamily;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.17px;
            color: #1a2744;
            position: relative;

            &:before {
                content: url("../assets/images/info.svg");
                position: absolute;
                left: -40px;
                top: 2px;
            }
        }

        .popup-description {
            margin-top: 9px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.11px;
            color: #1a2744;
        }
    }
    ::v-deep .vc-popover-caret{
        left: 87%!important;
    }
    ::v-deep .vc-popover-content{
        position: relative!important;
        left: 100%!important;
    }
}
</style>
